<template>
  <div class="landing-sso-page">
    <div class="landing-header">
      <a class="camfrog-logo" :href="baseUrl">
        <img src="@/assets/images/main/logo-grey.svg" alt="camfrog-logo">
      </a>
    </div>
    <div class="landing-sso-page-content">
      <div class="content-image">
        <img src="@/assets/images/main/sso-image.png" alt="#">
      </div>
      <div class="content-text">
        We will return you back to desktop client shortly
      </div>
      <div class="content-loader">
        <span class="loader-dot" />
        <span class="loader-dot" />
        <span class="loader-dot" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LandingSSO",
  computed: {
    baseUrl: () => process.env.VUE_APP_BASE_URL
  },

  created() {
    this.openClient()
  },

  methods: {
    openClient() {
      window.location = `camfrog://sso_authorize/${window.location.search}`
    },
  },

}
</script>

<style scoped lang="scss">
.landing-sso-page {
  height: 100%;
  width: 100%;
  position: relative;

  .landing-header {
    padding: 23px 30px;
    max-width: 95%;
    margin: 0 auto;
  }

  &-content {
    height: 100%;
    max-width: 1240px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 0 auto;
    padding: 160px 15px;
    .content-image {
      margin-bottom: 32px;
    }
    .content-text {
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      color: #363636;
      margin-bottom: 40px;
    }
    .content-loader {
      .loader-dot {
        width: 8px;
        height: 8px;
        display: inline-block;
        border-radius: 50%;
        background-color: #D9D9D9;
        animation: 1.5s blink infinite;
        &:not(:last-child) {
          margin-right: 8px;
        }
        &:nth-child(2) {
          animation-delay: 375ms;
        }
        &:nth-child(3) {
          animation-delay: 750ms;
        }
        @keyframes blink {
          50% {
            background-color: #2ECB4E;
          }
        }
      }
    }
  }
}
</style>
